import Tables from "../../Components/Tables/Table"
import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import Button from "../../Components/Buttons/Button"
import { useNavigate } from "react-router-dom"
import Protected from "../../Components/Protected/Protected"
import { useState, useEffect } from "react"


async function getData(endpoint) {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }
const columns = [
  {
    name: "Logo",
    selector: row => row.logo,
    sortable:true
},
    {
        name: "Name",
        selector: row => row.name,
        sortable:true
    },

    {
        name: "Start_Date",
        selector: row => row.start_date,
        sortable:true
    },
    {
        name: "Status",
        selector: row => row.status,
        sortable:true
    },
    {
        name: 'Action',
        selector: row => row.action,
        sortable:true
    }
  
];

export default function Seasons(){
    <Protected/>

    const [tableData,setTableData]= useState([]);
    const [allTableData,setAllTableData]= useState([]);
    const [pending,setPending]=useState(true);
    const [search, setSearch]=useState('');
    const navigate = useNavigate();
  
    useEffect(()=>{
    async function Loadallusers(){ 
        await getData('seasons').then((response) => { 
         // console.log(response);
            setPending(true);
            PopulateTable(response.data);
            setPending(false);
            setAllTableData(response.data);
            response.errors && console.log(response.errors);
       })}
       Loadallusers(); 
      
},[]);

const handleSearch=async()=>{
    try {
        setPending(true)
        let newTable = await allTableData.filter(row=>{
         //   console.log('search',row.firstname.includes(search))
            return row.firstname.toLowerCase().includes(search.toLowerCase())||row.lastname.toLowerCase().includes(search.toLowerCase())||row.email.toLowerCase().includes(search.toLowerCase());
        })
        PopulateTable(newTable)
        setPending(false)

    } catch (error) {
        console.log(error)
    }
}



function PopulateTable(newTable)
{
    let searchData = newTable.map(table=>{
        let imageUrl=process.env.REACT_APP_BASE_URL;
        let logo="";
       imageUrl= imageUrl.substring(0, imageUrl.length-4);
        if(table.logo)
        {
         logo =imageUrl.concat(table.logo.substring(1));
  
//alert(logo);
}
    
//console.log(imageUrl);
        return {
          logo:<div><img height="40" width="40" src={logo} alt="logo"/></div>,
         
            name:table.name,
            start_date:table.start_date,
            status:(table.active==1)?"Active":"Disqualified",
            action:<><button style={{color:"green",background:"white", border:"none", cursor:"pointer"}} onClick={() => onClick(table, logo)} > Edit
            </button><button style={{color:"red",background:"white", border:"none", cursor:"pointer"}} onClick={() => onDelete(table, logo)} > Delete
            </button><button style={{color:"blue",background:"white", border:"none", cursor:"pointer"}} onClick={() => CMS(table, logo)} > CMS
            </button></>
                                 
        }
    })
    setTableData(searchData);
}
 useEffect(()=>{
        handleSearch();
    },[search])
  useEffect(()=>{
        handleSearch();
    },[search])
function handleClick(){
    navigate('/add_Season',{state:{new:true}});
}
function onClick(rowdata,logo)
{
navigate('/add_Season',{state:{data:rowdata, logo:logo, new:false}});
}
function onDelete(rowdata, logo)
{

    navigate('/delete_Season',{state:{data:rowdata, logo:logo, new:false}});
}
function CMS(rowdata, logo)
{

    navigate('/season_cms',{state:{data:rowdata, logo:logo, new:false}});
}
    return (
        <div className={styles.maincontent}>
          
  <TitleRow>Seasons</TitleRow>
<Row height={20}>
  <div className={styles.actionbox}>
  <input type="text" placeholder="Search" value={search} onChange={(e)=>setSearch(e.target.value)} style={{background:"transparent"}}/>
  <Button onClick={handleClick} height={30} width={120} background={"rgb(155, 101, 96)"} color={"white"} value={"Add Contestant"}/>
  </div></Row>
      <Row>{pending?"Loading...":<Tables  progressPending={pending} data={tableData} columns={columns} paginate={true}/>}</Row>
        </div>
    )
}