
import Login from './Pages/LoginPage';
import './App.css';
import Header from './Components/Layout/Header';
import Sidenav from './Components/Layout/Sidenav';
import AddNewContestant from './Pages/ContestantPage/AddNewContestant';
import Contestants from './Pages/ContestantPage/ContestantPage';
import PurchasedTickets from './Pages/Tickets/PurchasedTickets';
import VerifyTickets from './Pages/Tickets/VerifieTickets';
import Tickets from './Pages/Tickets/Tickets';
import VoteHistory from './Pages/Votes/VoteHistory';
 import Vote from './Pages/ContestantPage/Votes';
import Dashboard from './Pages/DashboardPage';
import Users from './Pages/Users/Users';
import AddNewUser from './Pages/Users/AddNewUsers';
import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import Changepassword from './Pages/ChangePassword';
import DeleteUser from './Pages/Users/DeleteUser';
import DeleteContestant from './Pages/ContestantPage/DeleteContestant'
import Export from './Pages/Votes/Export';
import ContestantCMS from './Pages/ContestantPage/ContestantCMS';
import DeleteSeason from './Pages/SeasonPage/DeleteSeason';
import AddNewSeason from './Pages/SeasonPage/AddNewSeason';
import Seasons from "./Pages/SeasonPage/SeasonPage";
import SeasonCMS from "./Pages/SeasonPage/SeasonCMS"
import Single_Contestants from './Pages/ContestantPage/Single_contestants_page';
import Group_Contestants from './Pages/ContestantPage/Group_contestants_page';
import Qualified_Contestants from './Pages/ContestantPage/Qualified_Contestants';
import Votes from './Pages/ContestantPage/Contestant_votes';



function App() {
  return (
      
     <Router>
        {!sessionStorage.getItem('access_token')?
    <div className="App">
    <Routes>       
    <Route path="/login" element={<Login />} />
    <Route path="/" element={<Navigate replace to="/login" />} />

    </Routes>
  
  
  </div>
  :
    <div className="App">
        <Sidenav/> 
    <div className='righthalf'>
    <Header/>
    <Routes>       
    {/* <Route path="/login" element={<Login setToken={setToken}/>} /> */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route path="/changepassword" element={<Changepassword />} />
            <Route path="/single_contestants" element={<Single_Contestants />} />
            <Route path="/group_contestants" element={<Group_Contestants />} />
            <Route path="/Qualified_contestants" element={<Qualified_Contestants />} />
            <Route path="/contestants" element={<Contestants />} />
            <Route path="/contestant_cms" element={<ContestantCMS />} />
            <Route path="/addcontestant" element={<AddNewContestant />} />
            <Route path="/deletecontestant" element={<DeleteContestant />} />

             <Route path="/seasons" element={<Seasons />} />
            <Route path="/season_cms" element={<SeasonCMS />} />
            <Route path="/add_season" element={<AddNewSeason />} />
            <Route path="/delete_season" element={<DeleteSeason />} />

            <Route path="/votes" element={<Vote />} />
            <Route path="/votehistory" element={<VoteHistory />} />
            <Route path="/export" element={<Export />} />
            <Route path="/tickets" element={<Tickets />} />
            <Route path="/purchasedtickets" element={<PurchasedTickets />} />
            <Route path="/verifytickets" element={<VerifyTickets />} />
            <Route path="/users" element={<Users />} />
            <Route path="/adduser" element={<AddNewUser />} />
            <Route path="/deleteuser" element={<DeleteUser />} />
            <Route path="/cvotes" element={<Votes/>}/>
         
            </Routes>
    </div>  
  
    </div>}
    </Router>
    );
}

export default App;
