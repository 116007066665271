import styles from "./pages.module.css"
import Rowcard from "../Components/RowDivs/Rowcard"
import Row from "../Components/RowDivs/Row"
import TitleRow from "../Components/RowDivs/TitleRow"
import Graphs from "../Components/Graph/Graph"
import Tables from "../Components/Tables/Table"
import Protected from "../Components/Protected/Protected"
import { useState, useEffect } from "react"
import Logo from "../Resources/images/atinkaonline.com new2.png"

async function getData(endpoint, method='GET') {
    return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
      },
    })
      .then(data => data.json())
   }
const columns = [
    {
        name: 'Image',
        selector: row => row.image,
        sortable:true,
        headerStyle:{fontWeight:"bold",'whiteSpace': 'unset'},
     
    },
    {
        name: "Code",
        selector: row => row.code,
        sortable:true
    },
    {
        name: "Contestant's Name",
        selector: row => row.name,
        sortable:true
    },
    {
        name: "Nickname",
        selector: row => row.nickname,
        sortable:true
    },
    {
        name: 'Votes',
        selector: row => row.votes,
        sortable:true
    }
    // {
    //     cell: () => <button onClick={(e)=>alert(e.target)}>Action</button>,
    //     ignoreRowClick: false,
    //     allowOverflow: true,
    //     button: false
    // }
];

export default function Dashboard(){
    const [tableData,setTableData]= useState([]);
    const [graphData,setGraphdata]= useState([]);
    const [graphLabel,setGraphlabel]= useState([]);
    const [pending,setPending]=useState(true);
    <Protected/>

        const [summarydata, setSummarydata]=useState([]);
        useEffect(()=>{
            async function Loadvotesummary(){ 
         await getData('vote-summary').then((response) => { 
        response.data?setSummarydata(response.data):console.log(response.errors);
       
        })}
        async function Loadallvotes(){ 
            await getData('contestant-votes','POST').then((response) => { 
           


                const graph_Labels = response.data.slice(0,10).map(table=>{
                    return table.code+" "+table.name.trim().split(' ',1)
                })
                const graph_Data = response.data.slice(0,10).map(table=>{
                  
                    return table.vote_sum_vote?table.vote_sum_vote:0
                
                })
             
                setGraphlabel(graph_Labels);
                setGraphdata(graph_Data);

              response.errors && console.log(response.errors);
           })}
           Loadallvotes();
            Loadvotesummary();
            const interval=setInterval(()=>{ 
            Loadvotesummary();
            Loadallvotes();
           
            },10000);
            return ()=>{
              clearInterval(interval)
            }
           
    },[]);
    
    return (
        <div className={styles.maincontent}>
            <TitleRow height={20}>Dashboard</TitleRow>
        <Row>
            <Rowcard>
         Today
         <span className={styles.boldness}><span >{summarydata.currentDay}</span></span>
            </Rowcard>
            <Rowcard>
         This Week
            <span className={styles.boldness}><span >{summarydata.currentWeek}</span> </span>
            </Rowcard>
            <Rowcard>
            This Month
           <span className={styles.boldness}><span >{summarydata.currentMonth}</span> </span>
            </Rowcard>
            <Rowcard>
          All votes
           <span className={styles.boldness}><span >{summarydata.totalVote}</span> </span>
            </Rowcard>
          
           </Row>
          
        <Row background={"white"}><Graphs type={"line"} label={graphLabel} data={graphData}/></Row>

        {/* <Row background={"transparent"}><div className={styles.tables}>{pending?"Loading...":<Tables columns={columns} data={tableData} paginate={false}/>}</div><div className={styles.piechart}> </div></Row> */}
         <Row><img alt ="logo" src={Logo}></img></Row>
        </div>
    )
}