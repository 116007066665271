import styles from "../pages.module.css"
import TitleRow from "../../Components/RowDivs/TitleRow"
import Row from "../../Components/RowDivs/Row"
import InputForm from "../../Components/Form/inputForm"
import Swal from 'sweetalert2';
import {useLocation} from 'react-router-dom';

async function addUser(userData, endpoint) {

  return fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+sessionStorage.getItem('access_token')
    },
    body: JSON.stringify(userData)
  })
    .then(data => data.json())
 }

export default function AddNewUser(){

  const location =useLocation();
  const content = location.state;
  //console.log(location.state.data)

  const data=[
    !content.new?{ input:{
      type:"hidden",name:"id", value:content.data.id,
      validation:{required:true}
    }}:"",
    {
      input:{
              type:"text", placeholder:"Enter Firstname",name:"firstname", value:content.new?"":content.data.firstname,
              validation:{required:true,  maxLength:50, pattern:/[a-z]/}
            }
    },
    {
        input:{
                type:"text", placeholder:"Enter Lastname",name:"lastname", value:content.new?"":content.data.lastname,
                validation:{required:true,  maxLength:50, pattern:/[a-z]/}
              }
      },
      {
      input:{
        type:"email", placeholder:"Enter Email",name:"email", value:content.new?"":content.data.email,
        validation:{required:true,  maxLength:50, pattern:/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/}
      }
},
{
  select:{
          type:"role",name:"role", value:content.new?"":content.data.role,
          validation:{required:true,  Min:8 },
          options:[ 
                    {value:"admin", label:"Admin"},
                    {value:"user", label:"User"},      
                 ],
                 defaultvalue:"admin"           
        }
},
{
select:{
  type:"active",name:"active", value:content.new?"":content.data.active,
  validation:{required:true,  Min:8 },
  options:[ 
            {value:true, label:"Active"},
            {value:false, label:"Inactive"},      
         ],
         defaultvalue:true           
}
},
     
    
    {
      submit:{
              type:"submit", value:content.new?"Add":"Update"
            }
    }
  ];


  async function Onsubmit(userData, e){
    e.preventDefault(); 
  //console.log(userData);
  const endpoint = content.new?"create_user":"update_user";
  await addUser(userData, endpoint).then((response) => { 
      response.data&& 
    Swal.fire({
      title: content.new?'New User Added':"User Updated",
      html:`<b>Firstname:</b> ${response.data.firstname}<br> <b>Lastname:</b> ${response.data.lastname} <br>
      ${response.data.email}<br> <b>Role:</b> ${response.data.role}`,
      icon: 'success',
      timer: 5000,
    });
        
    response.errors && Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: JSON.stringify(response.errors[0]),
      timer:5000
     
    })
      
      });
    }
    return (
        <div className={styles.maincontent}>  <TitleRow>Add New User</TitleRow>
        <Row>
        <InputForm  data={data} onSubmit={Onsubmit} />
   

        </Row>
        </div>
    )
}