import styles from "./layout.module.css"
import {Link} from "react-router-dom"
import Electricity from "../electricity"
import Logo from "../../Resources/images/atinkaonline.com new2.png"
import { useState } from "react"
import 'font-awesome/css/font-awesome.min.css';

export default function Sidenav(){
    const [showvotes, setShowvotes]=useState(false);
    const [showtickets, setShowtickets]=useState(false);

    function onClick_showvotes(){
        setShowtickets(false);
        setShowvotes(showvotes?false:true);
        
    }
    function onClick_showtickets(){
        setShowvotes(false);
        setShowtickets(showtickets?false:true);
    }
    return(<div className={styles.sidenav}>
        <div className={styles.sidenavlogo}>
        <div className={styles.loginImageCaption}>
          <div className={styles.loginLogo}>
            <img src={Logo} className={styles.loginLogo} alt="logo"/>
            </div>
            <span className={styles.loginImageCaption_1}></span>
            <span className={styles.loginImageCaption_2}>NEUTEK MEDIA</span>
     
          </div>
        </div>
        <div className={styles.sidenavlinkscontainer}>
        <Link to="/" className={styles.sidenavlinks}>  
        <span className={styles.sidenavlinktext}>
        <i className="fa fa-dashboard"></i> 	 Dashboard
        </span>
            </Link>
            <Link to="/users" className={styles.sidenavlinks}>
            <span className={styles.sidenavlinktext}>
            <i className="fa fa-user" aria-hidden="true"></i> 	 Users
            </span>
            </Link>
            <Link to="/qualified_contestants" className={styles.sidenavlinks}>
            <span className={styles.sidenavlinktext}>
            <i className="fa fa-users" aria-hidden="true"> </i> 	  Contestants
            </span>
            </Link>     
           
            {/* <Link to="/qualified_contestants" className={styles.sidenavlinks} onClick={onClick_showvotes}>
            <span className={styles.sidenavlinktext}>
        <i className="fa fa-user" aria-hidden="true"></i>	&nbsp; <b> Contestants </b>
        </span>
        </Link> */}

        <Link to="/votes" className={styles.sidenavlinks}>
            <span className={styles.sidenavlinktext}>
            <i className="fa fa-user" aria-hidden="true"></i> 	 votes
            </span>
            </Link>    
        </div>

    </div>)
}


export function MiniSidebar()
{

    const [ display, setDisplay ] = useState( 'none' )

    function handleClick() {

        if ( display == 'none' ) {

            setDisplay( 'block' )

        } else {

            setDisplay( 'none' )

        }

    }



    return(


        <div className={styles.minisidebar}> 
            
             <div className={styles.actionlogo} >
     <button onClick={handleClick}> <i className="fa fa-bars" aria-hidden="true"></i></button>

        </div>
        <div className={styles.dropdownmenu} style={{display:display}}>
             <Link to="/" className={styles.sidenavlinks} >  
                Dashboard
            </Link>
            <Link to="/contestants" className={styles.sidenavlinks}>
                Contestants
            </Link>
            <Link to="/votes" className={styles.sidenavlinks}>
            <b> Votes</b>
            </Link>
            <Link to="/votehistory"className={styles.sidenavlinks}>
                Vote History
            </Link>
            <Link to="/tickets" className={styles.sidenavlinks}>
            <b> Tickets</b>
            </Link>
            <Link to="/purchasedtickets" className={styles.sidenavlinks}>         
                Purchased Tickets      
            </Link>
            <Link to="/verifytickets" className={styles.sidenavlinks}>        
                Verify Tickets   
            </Link> 
           <Link to="/users" className={styles.sidenavlinks}>
               Users
            </Link>
        </div>
        </div>
    )
}